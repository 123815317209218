import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import ReactMarkdown from 'react-markdown';

const Team = () => {
  const {
    markdownRemark: {
      frontmatter: { title, subtitle, section1, section2 },
    },
  } = useStaticQuery(graphql`
    query TeamPageQuery {
      markdownRemark(frontmatter: { pageName: { eq: "team" } }) {
        frontmatter {
          title
          subtitle
          section1 {
            description
            title
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          section2 {
            description
            image1caption
            title
            image2caption
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            image2 {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Layout fullMenu>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>{title}</h2>
            <p>{subtitle}</p>
          </div>
        </header>

        <div className="wrapper">
          <div className="inner">
            <h3 className="major">{section1.title}</h3>

            <div className="row">
              <div className="col-6 col-12-small">
                <p>{section1.description}</p>
              </div>
              <div className="col-6 col-12-small">
                <span className="image fit">
                  <a
                    href={section1.image.publicURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Img fluid={section1.image.childImageSharp.fluid} alt="" />
                  </a>
                </span>
              </div>
            </div>

            <h3 className="major">{section2.title}</h3>

            <ReactMarkdown source={section2.description} />

            <section className="features">
              <article>
                <a
                  href={section2.image.publicURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image"
                >
                  <Img fluid={section2.image.childImageSharp.fluid} alt="" />
                </a>
                <p>{section2.image1caption}</p>
              </article>
              <article>
                <a
                  href={section2.image2.publicURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image"
                >
                  <Img fluid={section2.image2.childImageSharp.fluid} alt="" />
                </a>
                {/* <h3 className="major">Nisl placerat</h3> */}
                <p>{section2.image2caption}</p>
              </article>
            </section>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Team;
